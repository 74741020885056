<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <q-form ref="editForm">
        <c-card title="예약 정보 상세" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline>
              <c-btn v-if="editable" label="예약" icon="save" @btnClicked="save" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <!-- <div class="col-6">
              <c-text
                :required="true"
                label='예약명'
                :editable="editable"
                name="reserveName"
                v-model="data.reserveName"
              />
            </div> -->
            <div class="col-4">
              <c-datepicker
                label='예약시간'
                :range="true"
                :minute="10"
                :editable="editable"
                type="time"
                name="reserveTime"
                v-model="data.reserveTime"
              />
            </div>
            <div class="col-4">
              <c-field
                :required="true"
                label='예약자'
                :editable="editable"
                name="reserveUserId"
                v-model="data.reserveUserId"
                @username="username"
              />
            </div>
            <div class="col-4">
              <c-plant
                :required="true"
                :disabled="disable"
                :editable="editable"
                type="edit"
                name="plantCd"
                v-model="data.plantCd"
              />
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'checkup-reserve-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        safetySuppliesGiveId: '',
        plantCd: '',
      }),
    },
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      data: {
        plantCd: null,
        reserveName: '',
        reserveTime: [],
        reserveUserId: '',
        reserveUserName: '',
      },
      grid: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '보호구종류',
            style: 'width:120px',
            align: 'left',
            type: 'select',
            comboItems: [
              { code: '1', codeName: '안전모' },
              { code: '2', codeName: '안전화' },
              { code: '3', codeName: '안전장갑' },
              { code: '4', codeName: '마스크' },
              { code: '5', codeName: '보호구' },
              { code: '6', codeName: '보호복' },
            ],
            setHeader: true,
            sortable: false,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '보호구명',
            style: 'width:200px',
            align: 'left',
            type: 'text',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '신청수량',
            style: 'width:80px',
            align: 'right',
            type: 'number',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'col4',
            field: 'col4',
            label: '비고',
            align: 'left',
            type: 'text',
            setHeader: true,
            sortable: false,
          },
        ],
        data: [],
      },
      typeItems: [
        { code: 'Y', codeName: '전체' },
        { code: 'N', codeName: '일자별' },
      ],
      searchParam: {
        plantCd: null,
        giveDt: '',
        safetySuppliesGiveId: '',
      },
      editable: true,
      disable: false,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.getList();
    },
    getList() {
      this.grid.data = [
        {
          col1: '1',
          col2: '안전모',
          col3: '10',
          col4: '',
        },
        {
          col1: '2',
          col2: '발등안전화',
          col3: '10',
          col4: '',
        },
        {
          col1: '3',
          col2: '안전 방진장갑',
          col3: '10',
          col4: '',
        },
        {
          col1: '4',
          col2: '방진마스크',
          col3: '5',
          col4: '',
        },
        {
          col1: '6',
          col2: '내전압용 절연복',
          col3: '5',
          col4: '',
        },
      ]
    },
    apprRequest() { 
    },
    completeRequest() { 
    },
    addrowGive() {
      this.grid.data.splice(0, 0, {

      })
    },
    saveRequest() {
    },
    removeGive() {
    },
    save() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '예약하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.$emit('closePopup', {data: this.data})
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    username(name) {
      this.data.reserveUserName = name
    }
  },
};
</script>
